import { MbmProvider } from '@amzn/react-arb-tools';
import React from 'react';
import { DEFAULT_LC_BUILDER, DEFAULT_LOCALE } from '../constants/constants';
import arbManifest from '../../build/i18n/arbManifest';

interface PropsType {
  children: JSX.Element;
}

const PantherI18nWrapper: React.FC<PropsType> = (props) => {
  let urlPrefix;
  let resolveBundleUrl;
  if (window.location.hostname === 'localhost') {
    urlPrefix = '/i18n';
  } else {
    resolveBundleUrl = (url?: string) =>
      url ? `https://gamma-pdx.assessments.security.amazon.dev/i18n/${url}` : undefined;
  }

  const mbmOptions = {
    arbManifest,
    defaultLocalizationContext: DEFAULT_LC_BUILDER.withLocale(DEFAULT_LOCALE).withDefaultLocale(DEFAULT_LOCALE).build(),
    urlPrefix,
    resolveBundleUrl,
  };

  return <MbmProvider {...mbmOptions}>{props.children}</MbmProvider>;
};

export default PantherI18nWrapper;
